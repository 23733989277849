import { store } from '../store/storeMain'
//import { transactionManager } from './transactionManager'
import { globalFunctions } from './globalFunctions'
import { budgetManager } from './budgetManager'

export const  widgetManager = {
    tableData: [],
    tableTotal: 0,
    itemsMinusDeleted: [],
    tableRow: {
        name: '',
        value: 0,
        percentage: 0
    },
    clearTableRow: function () {
        this.tableRow.name = ''
        this.tableRow.value = 0
        this.tableRow.percentage = 0
    },
    buildTable: function (period) {
        for (let i = 0; i < this.itemsMinusDeleted.length; i++) {

            if (this.isItemWithinPeriod(
                this.itemsMinusDeleted[i].transactionBalances[this.itemsMinusDeleted[i].transactionBalances.length - 1].transactionDate,
                period)) {

                this.convertToTableRow(this.itemsMinusDeleted[i])

                this.addToTableData()

                this.calculatePercentages()
            }
        }
    },
    calculatePercentages: function () {
        for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].percentage = ((parseFloat(this.tableData[i].value) / parseFloat(this.tableTotal)) * 100).toFixed(2)
        }
    },
    addToTableData: function () {
        let item = this.tableRow

        if (this.tableData.length === 0) {
            this.tableData.push(globalFunctions.stringify(item))
            this.tableTotal = parseFloat(this.tableTotal) + parseFloat(item.value)
        }
        else {
            for (let i = 0; i < this.tableData.length; i++) {
                if (this.tableData[i].name === item.name) {
                    this.tableData[i].value = parseFloat(this.tableData[i].value) + parseFloat(item.value)
                    this.tableTotal = parseFloat(this.tableTotal) + parseFloat(item.value)
                    break
                }
                else if (i === this.tableData.length - 1) {
                    this.tableData.push(globalFunctions.stringify(item))
                    this.tableTotal = parseFloat(this.tableTotal) + parseFloat(item.value)
                    break
                }
            }
        }
    },
    convertToTableRow: function (item) {
        this.clearTableRow()

        this.tableRow.name = item.category

        for (let i = 0; i < item.transactionBalances.length; i++) {
            this.tableRow.value = parseFloat(this.tableRow.value) + parseFloat(item.transactionBalances[i].transactionAmount)
        }
    },
    clearOldData: function () {
        this.tableData = []
        this.tableTotal = 0,
        this.itemsMinusDeleted = []
    },
    isItemWithinPeriod: function (itemDate, period) {
        let start = ''
        let end = ''
        let date = new Date(this.returnFormattedDateFromCalendar(itemDate))

        let dateSplit = period.split(" - ")

        start = new Date(dateSplit[0])
        end = new Date(dateSplit[1])

        if (date >= start && date <= end) {
            return true
        }

        return false
    },
    returnFormattedDateFromCalendar(date) {
        let dateSplit = date.split("-")

        return (dateSplit[0] + "-" + parseInt(dateSplit[1]) + "-" + (parseInt(dateSplit[2])))
    },
    

    ///////////////////////////////////////////////////////////////////////
    //Income Specific
    ///////////////////////////////////////////////////////////////////////

    buildIncomeCategoryTotals: function (period) {
        this.clearOldData()
        this.getIncomeItemsFromStoreAndRemoveDeletedItems()

        this.buildTable(period)

        return {
            tableData: this.tableData,
            tableTotal: this.tableTotal
        }
    },
    getIncomeItemsFromStoreAndRemoveDeletedItems: function () {
        this.items = this.getIncomeTransactions(store.state.Transactions)


        ///Remove deleted items from items array
        this.itemsMinusDeleted = globalFunctions.removeDeletedObjectsFromItems(this.items)
    },
    getIncomeTransactions: function (items) {
        let incomes = []

        for (let i = 0; i < items.length; i++) {
            if (items[i].transactionType === "income") {
                incomes.push(items[i])
            }
        }

        return incomes
    },


    ///////////////////////////////////////////////////////////////////////
    //Expense Specific
    ///////////////////////////////////////////////////////////////////////

    buildExpenseCategoryTotals: function (period) {
        this.clearOldData()
        this.getExpenseItemsFromStoreAndRemoveDeletedItems()

        this.buildTable(period)

        return {
            tableData: this.tableData,
            tableTotal: this.tableTotal
        }
    },
    getExpenseItemsFromStoreAndRemoveDeletedItems: function () {
        this.items = this.getExpenseTransactions(store.state.Transactions)

        ///Remove deleted items from items array
        this.itemsMinusDeleted = globalFunctions.removeDeletedObjectsFromItems(this.items)
    },
    getExpenseTransactions: function (items) {
        let expenses = []

        for (let i = 0; i < items.length; i++) {
            if (items[i].transactionType === "expense") {
                expenses.push(items[i])
            }
        }

        return expenses
    },


    ///////////////////////////////////////////////////////////////////////
    //Budget Specific
    ///////////////////////////////////////////////////////////////////////

    buildBudgetTotals: function (date) {
        this.clearOldData()
        this.getBudgetItemsFromStoreAndRemoveDeletedItems()

        this.createTableRows()

        this.buildBudgetTable(date)

        if (this.tableData[0].value === 0 && this.tableData[1].value === 0) {
            this.tableData = []
        }

        return {
            tableData: this.tableData,
            tableTotal: this.tableTotal
        }
    },
    buildSingleBudgetTotals: function (budgetId, date) {
        this.clearOldData()
        this.getBudgetItemsFromStoreAndRemoveDeletedItems()

        this.createTableRows()

        this.buildSingleBudgetTable(budgetManager.findBudgetById(budgetId), date)

        if (this.tableData[0].value === 0 && this.tableData[1].value === 0) {
            this.tableData = []
        }

        return {
            tableData: this.tableData,
            tableTotal: this.tableTotal
        }
    },
    getBudgetItemsFromStoreAndRemoveDeletedItems: function () {
        this.items = store.state.Budgets


        ///Remove deleted items from items array
        this.itemsMinusDeleted = globalFunctions.removeDeletedObjectsFromItems(this.items)
    },
    createTableRows: function () {
        this.tableData.push({
            name: 'Spent',
            value: 0,
            percentage: 0
        },
        {
            name: 'Remaining',
            value: 0,
            percentage: 0
        })
    },
    buildBudgetTable: function (date) {
        for (let i = 0; i < this.itemsMinusDeleted.length; i++) {
            let budgetBalance = budgetManager.getActiveBudgetBalance(this.itemsMinusDeleted[i], date)

            this.addToBudgetTableData(budgetBalance)

            this.calculatePercentages()
        }
    },
    buildSingleBudgetTable: function (budget, date) {
        for (let i = 0; i < this.itemsMinusDeleted.length; i++) {
            if (this.itemsMinusDeleted[i].budgetName === budget.budgetName) {
                let budgetBalance = budgetManager.getActiveBudgetBalance(this.itemsMinusDeleted[i], date)

                this.addToBudgetTableData(budgetBalance)

                this.calculatePercentages()
            }
        }
    },
    addToBudgetTableData: function (item) {
        //Update Spent
        this.tableData[0].value = parseFloat(this.tableData[0].value) + parseFloat(item.amountSpent)

        //Update Remaining
        this.tableData[1].value = parseFloat(this.tableData[1].value) + (parseFloat(item.budgetAmount) - parseFloat(item.amountSpent))

        this.tableTotal = parseFloat(this.tableTotal) + parseFloat(item.budgetAmount)
    },

}


///////////////////////////////////////////////////////////////////////
//Income Items By Category Functions
///////////////////////////////////////////////////////////////////////

export const returnIncomeCategoriesAndThereItems = (period) => {
    let items = getIncomeItemsFromStoreAndRemoveDeletedItems()

    return buildJsonObject(period, items)
}

export const getIncomeItemsFromStoreAndRemoveDeletedItems = () => {
    let items = widgetManager.getIncomeTransactions(store.state.Transactions)

    ///Remove deleted items from items array
    return globalFunctions.removeDeletedObjectsFromItems(items)
}

export const buildJsonObject = (period, items) => {
    let jsonObject = []

    for (let i = 0; i < items.length; i++) {

        if (widgetManager.isItemWithinPeriod(
            items[i].transactionBalances[items[0].transactionBalances.length - 1].transactionDate,
            period)) {

            if (jsonObject.length === 0) {
                jsonObject[0] = [items[i].category, [items[i]]]

                continue
            }

            for (let k = 0; k < jsonObject.length; k++) {
                if (jsonObject[k][0] === items[i].category) {
                    jsonObject[k][1].push(items[i])

                    continue
                } else if (k === jsonObject.length - 1) {
                    jsonObject.push([items[i].category, [items[i]]])
                    break
                }
            }
        }
    }

    return jsonObject
}

///////////////////////////////////////////////////////////////////////
//End Of Income Items By Category Functions
///////////////////////////////////////////////////////////////////////


///////////////////////////////////////////////////////////////////////
//Expense Items By Category Functions
///////////////////////////////////////////////////////////////////////

export const returnExpenseCategoriesAndThereItems = (period) => {
    let items = getExpenseItemsFromStoreAndRemoveDeletedItems()

    return buildJsonObject(period, items)
}

export const getExpenseItemsFromStoreAndRemoveDeletedItems = () => {
    let items = widgetManager.getExpenseTransactions(store.state.Transactions)

    ///Remove deleted items from items array
    return globalFunctions.removeDeletedObjectsFromItems(items)
}

///////////////////////////////////////////////////////////////////////
//End Of Expense Items By Category Functions
///////////////////////////////////////////////////////////////////////

