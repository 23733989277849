<template>
    <v-card>
        <donutChart style="height: 200px;" :value="tableData"></donutChart>

        <v-simple-table fixed-header>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">CATEGORY</th>
                        <th class="text-left"><span style="color:#4CAF50">INCOME</span></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in tableData" :key="item.name">
                        <td>{{item.name}}</td>
                        <td><span style="color:#4CAF50">+ {{asMoney(item.value)}}</span></td>
                        <td>{{item.percentage}}%</td>
                    </tr>
                    <tr>
                        <td><b>TOTAL</b></td>
                        <td><b><span style="color:#4CAF50">+ {{asMoney(tableTotal)}}</span></b></td>
                        <td><b>100%</b></td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>
</template>

<script>

    import { formatMoney } from '../../../../javascript/globalFunctions'
    import { widgetManager } from '../../../../javascript/widgetManager'

    export default {
        components: {
            donutChart: () => import('../../../charts/donutChart.vue')
        },
        props:['period'],
        data() {
            return {
                tableData: [],
                tableTotal: 0,
                formattedTableTotal: '',
            }
        },
        computed: {
            asMoney: function () {
                return (amount) => {
                    return formatMoney(amount, 2, ".", ",")
                }
            }
        },
        methods: {
            formatAccountAmount() {
                this.formattedTableTotal = formatMoney(this.tableTotal, 2, ".", ",")
            },
            updateTotals() {
                let table = widgetManager.buildIncomeCategoryTotals(this.period)
                this.tableData = table.tableData
                this.tableTotal = table.tableTotal

                this.formatAccountAmount()
            }
        },
        watch: {
            "period": function () {
                this.updateTotals()
            }
        },
        beforeMount() {
            this.updateTotals()
        }
    }
</script>